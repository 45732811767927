import { ComponentProps } from 'react';
import classNames from 'classnames';
import styles from './AgreementLinkCard.module.scss';
import { Heading, Grid, GridCell, Link, Icon } from 'ui/components';
import { ReactComponent as IconRightArrow } from 'ui/icons/icon-chevron-right.svg';
import { capitalizeFirstLetter, addText } from 'helpers/string';

export interface AgreementLinkCardProps {
	isActionLink?: boolean;
	className?: string;
	link?: {
		url: string;
		name: string;
	};
}

export const AgreementLinkCard: React.FC<AgreementLinkCardProps> = ({ className, link, isActionLink }) => {
	return (
		<Grid className={classNames(styles.AgreementLinkCard, className)} center={true}>
			<GridCell>
				<Link url={link?.url} style="sm" className={styles.AgreementLinkCard_link_container} ariaLabel={link?.name} target="_self">
					{isActionLink ? addText(capitalizeFirstLetter(link?.name), 0, 0, 'Se') : capitalizeFirstLetter(link?.name)}

					<Icon size="md" aria-hidden={false} className={styles.AgreementLinkCard_link_icon}>
						<IconRightArrow viewBox="0 0 250 250" />
					</Icon>
				</Link>
			</GridCell>
		</Grid>
	);
};
