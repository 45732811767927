import React from 'react';
import { VerticalTabMenu } from '../VerticalTabMenu';
import { TabMenu } from '../TabMenu';
import { AgreementContent } from './AgreementContent';
import styles from './Agreement.module.scss';
import { Container } from 'ui/components';

type ChapterMetaData = {
	id: string;
	title: string;
};

type SectionMetaData = {
	id: string;
	title: string;
};

type AgreementProps = {
	chapters: ChapterMetaData[];
	selectedChapterValue: string;
	onChapterChange: (chapterId: string) => void;
	sections: SectionMetaData[];
	selectedSectionValue: string;
	onSectionChange: (sectionId: string) => void;
	contentHtml: string;
	expandButtonText: string;
	collapseButtonText: string;
	href?: string;
};
export const Agreement: React.VFC<AgreementProps> = ({
	chapters,
	selectedChapterValue,
	onChapterChange,
	sections,
	selectedSectionValue,
	onSectionChange,
	contentHtml,
	expandButtonText,
	collapseButtonText,
	href,
}) => {
	const getQueryParams = () => {
		const searchParams = new URLSearchParams(window.location.search);
		return {
			documentId: searchParams.get('documentId'),
			chapterId: searchParams.get('chapterId'),
			sectionId: searchParams.get('sectionId'),
		};
	};
	const queryParams = getQueryParams(); // Get current query parameters
	return (
		<Container width="Standard" className={styles.agreementWrapper}>
			<TabMenu
				items={chapters.map((chapter) => ({
					value: chapter.id,
					label: chapter.title,
				}))}
				selectedItemValue={selectedChapterValue}
				onItemClick={(value) => onChapterChange(value)}
			/>
			<div className={styles.chapterContentWrapper}>
				<div className={styles.verticalTabMenuWrapper}>
					<VerticalTabMenu
						items={sections.map((section) => ({
							value: section.id,
							label: section.title,
							href: `${href}?documentId=${queryParams.documentId}&chapterId=${queryParams.chapterId}&sectionId=${section.id}`,
						}))}
						selectedItemValue={selectedSectionValue}
						onItemClick={(value) => onSectionChange(value)}
					/>
				</div>
				<AgreementContent content={contentHtml} expandButtonText={expandButtonText} collapseButtonText={collapseButtonText} />
			</div>
		</Container>
	);
};
