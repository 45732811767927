import classNames from 'classnames';
import styles from './DesktopVerticalTabMenuItem.module.scss';

type DesktopVerticalTabMenuItemProps = {
	selected: boolean;
	className?: string;
	children: React.ReactNode;
	href: string;
	onClick?: () => void;
};

export const DesktopVerticalTabMenuItem: React.FC<DesktopVerticalTabMenuItemProps> = ({
	selected,
	className,
	href,
	children,
	onClick,
	...restProps
}) => {
	return (
		<li>
			<button
				className={classNames(
					styles.button,
					{
						[styles.button_selected]: selected,
					},
					className,
				)}
				onClick={onClick}
				{...restProps}
				title={typeof children === 'string' ? children : undefined}
			>
				{children}
			</button>
			{/* a tag is only used for Cludo crawlers and not UI */}
			<a
				tabIndex={-1}
				href={href}
				className={classNames(styles.button___links, className)}
				title={typeof children === 'string' ? children : undefined}
				{...restProps}
			>
				<span>{children}</span>
			</a>
		</li>
	);
};
