import { useIsMobile } from 'helpers/media-queries';
import React from 'react';
import { DesktopVerticalTabMenu } from './DesktopVerticalTabMenu';
import { MobileVerticalTabMenu } from './MobileVerticalTabMenu/MobileVerticalTabMenu';

export type VerticalTabMenuItemValue = string;

type VerticalTabMenuProps = {
	items: Array<{
		value: VerticalTabMenuItemValue;
		label: string;
		href?: string;
	}>;
	selectedItemValue: VerticalTabMenuItemValue;
	onItemClick: (value: VerticalTabMenuItemValue) => void;
};
export const VerticalTabMenu: React.VFC<VerticalTabMenuProps> = ({ items, selectedItemValue, onItemClick }) => {
	const isMobile = useIsMobile();

	return isMobile ? (
		<MobileVerticalTabMenu items={items} selectedItemValue={selectedItemValue} onItemClick={onItemClick} />
	) : (
		<DesktopVerticalTabMenu items={items} selectedItemValue={selectedItemValue} onItemClick={onItemClick} />
	);
};
