import { Member } from 'application/repositories/membersRepository';
import React, { ComponentProps } from 'react';
import classNames from 'classnames';
import { formatDate } from 'helpers/date';
import { Container, GridCell, Heading, Link, Spinner, CardLabel } from 'ui/components';
import styles from './WelcomeCard.module.scss';
export interface WelcomeCardProps {
	className?: string;
	member: Member;
	members: Member[];
	welcomeTitle?: string;
	memberNumberTitle?:string;
	representSectionTitle?: string;
	agreementSectionTitle?: string;
	memberSectionTitle?: string;
	memberNumberLabel?: string;
	primaryAgreementDepartment?: string;
	agreementLink?: string;
	memberLink?: {
		name: string;
		url: string;
	};
	dateTitle?: string;
	agreementDefaultLink?: Umbraco.Link;
}

export const WelcomeCard: React.FC<WelcomeCardProps> = ({
	className,
	member,
	members,
	welcomeTitle,
	memberNumberTitle,
	representSectionTitle,
	agreementSectionTitle,
	memberSectionTitle,
	memberNumberLabel,
	primaryAgreementDepartment,
	agreementLink,
	memberLink,
	dateTitle,
	agreementDefaultLink
}) => {
	const highlightedMembers = members?.filter((obj) => {
		return obj.isHighlighted === true;
	});

	// Get the event type of each member
	const eventTypes = [];
	highlightedMembers?.forEach((elements) => {
		const eventColor: ComponentProps<typeof CardLabel>['backgroundColor'] =
			elements.event?.type === 'Udlært'
				? 'var(--color-tag-blue)'
				: elements.event?.type === 'Nyansat'
					? 'var(--color-tag-green)'
					: elements.event?.type === 'Jubilæum'
						? 'var(--color-tag-orange)'
						: elements.event?.type === 'Fødselsdag'
							? 'var(--color-tag-red)'
							: elements.event?.type === 'Nyt medlem'
								? 'var(--color-primary)'
								: null;
		eventTypes.push(eventColor);
	});

	return (
		<>
			{!member ? (
				<Spinner />
			) : (
				<Container className={classNames(styles.WelcomeCard, className)} width="Standard">
					<GridCell>

						<section className={styles.WelcomeCard_top}>
							<div className={styles.WelcomeCard_name}>
								<Heading headingLevel="h2" className={styles.WelcomeCard_heading}>
								<span className={styles.WelcomeCard_greeting}>{welcomeTitle} {member.name}</span>
								</Heading>
							</div>
							<div>

							{memberNumberTitle && (
    							<>
							<span className={styles.WelcomeCard_memberNumberTitle}>{memberNumberTitle}</span>
							<span className={styles.WelcomeCard_memberNumberValue}>{member.memberId}</span>
								</>
							)}
							</div>
							
						</section>
						<div className={styles.WelcomeCard_sectionWrapper}>
							<section className={styles.WelcomeCard_section}>
								<p className={styles.WelcomeCard_sectionTitle}>{representSectionTitle}</p>

								<div className={styles.WelcomeCard_representPrimary}>
									<p className={styles.WelcomeCard_representPrimaryTitle}>{member?.chairedTitles[0]?.chairedTitle}</p>
									<span className={styles.WelcomeCard_representDepartment}>{member.company}</span>
									<div className={styles.WelcomeCard_dateWrapper}>
										<span className={styles.WelcomeCard_representPrimaryDateLabel}>{dateTitle}</span>
										<span className={styles.WelcomeCard_representPrimaryDate}>
											{formatDate(member?.chairedTitles[0]?.actingMemberPeriod, 'dd.mm.yyyy')}
										</span>
									</div>
								</div>
								{member.chairedTitles[1] && (
									<div className={styles.WelcomeCard_representPrimary}>
										<p className={styles.WelcomeCard_representPrimaryTitle}>{member.chairedTitles[1].chairedTitle}</p>
										<span className={styles.WelcomeCard_representDepartment}>{member.company}</span>
										<div className={styles.WelcomeCard_dateWrapper}>
											<span className={styles.WelcomeCard_representPrimaryDateLabel}>{dateTitle}</span>
											<span className={styles.WelcomeCard_representPrimaryDate}>
												{formatDate(member.chairedTitles[1].actingMemberPeriod, 'dd.mm.yyyy')}
											</span>
										</div>
									</div>
								)}
							</section>
							<section className={styles.WelcomeCard_section}>
								<p className={styles.WelcomeCard_sectionTitle}>{agreementSectionTitle}</p>
								<div className={styles.WelcomeCard_agreementPrimary}>
									<span className={styles.WelcomeCard_primaryAgreementDepartment}>
										{member?.linkedAgreements[0]?.name || (
											<>
												<p>	Vi har ikke registreret nogen arbejdsplads.</p>

												<p>	Hvis det er en fejl, så kontakt din lokalafdeling. </p>
											</>
										)}
										{primaryAgreementDepartment}
									</span>

									{(member.linkedAgreements.length > 0 || agreementDefaultLink?.url) && (
										<div className={styles.WelcomeCard_linkWrapper}>
											<Link className={styles.WelcomeCard_link} url={member.linkedAgreements[0]?.url || agreementDefaultLink?.url} title={agreementLink}>
												{agreementLink}
											</Link>
										</div>
									)}
								</div>
							</section>
							<section className={styles.WelcomeCard_section}>
								<p className={styles.WelcomeCard_sectionTitle}>{memberSectionTitle}</p>
								{!members ? <Spinner /> : <span className={styles.WelcomeCard_memberNumber}>{members.length}</span>}
								<span className={styles.WelcomeCard_memberNumberLabel}>{memberNumberLabel}</span>
								<div className={styles.WelcomeCard_memberOverview}>
									{highlightedMembers?.[0]?.event && (
										<div className={styles.WelcomeCard_memberRow}>
											{eventTypes.length > 0 ? (
												<span className={styles.WelcomeCard_memberCategory} style={{ backgroundColor: `${eventTypes[0]}` }}>
													{highlightedMembers?.[0]?.event?.type}
												</span>
											) : null}

											<div className={styles.WelcomeCard_memberInfo}>
												<span className={styles.WelcomeCard_memberName}>{highlightedMembers?.[0].name}</span>
												<span className={styles.WelcomeCard_memberTitle}>{highlightedMembers?.[0].job}</span>

												<span className={styles.WelcomeCard_memberDate}>
													{formatDate(highlightedMembers?.[0].event?.date, 'dd.mm.yyyy')}
												</span>
											</div>
										</div>
									)}

									{highlightedMembers?.[1] && (
										<div className={styles.WelcomeCard_memberRow}>
											{highlightedMembers?.[1].event && (
												<div>
													{eventTypes.length > 0 ? (
														<span
															className={styles.WelcomeCard_memberCategory}
															style={{ backgroundColor: `${eventTypes[1]}` }}
														>
															{highlightedMembers?.[1]?.event?.type}
														</span>
													) : null}

													<div className={styles.WelcomeCard_memberInfo}>
														<span className={styles.WelcomeCard_memberName}>{highlightedMembers?.[1].name}</span>
														<span className={styles.WelcomeCard_memberTitle}>{highlightedMembers?.[1].job}</span>

														<span className={styles.WelcomeCard_memberDate}>
															{formatDate(highlightedMembers?.[1].event?.date, 'dd.mm.yyyy')}
														</span>
													</div>
												</div>
											)}
										</div>
									)}
								</div>
								{memberLink && (
									<div className={styles.WelcomeCard_linkWrapper}>
										<Link className={styles.WelcomeCard_link} url={memberLink.url} title={memberLink.name}>
											{memberLink.name}
										</Link>
									</div>
								)}
							</section>
						</div>
					</GridCell >
				</Container >
			)}
		</>
	);
};
