import { createContext, useEffect, useState } from 'react';
import { Profiles, getProfiles } from 'application/repositories/profilesRepository';
import { getCMSHostname } from 'helpers/getCMSHostname';
import { useSession } from 'next-auth/react';
import { GetContactResult, getContact } from 'application/adapters/contacts/contactsAdapter';
import { Audiences, getAudiences } from 'application/repositories/AudiencesRepository';
export const ProfileContext = createContext<{ profile: Profiles; branches: GetContactResult; audiences: Audiences }>(null);

export const ProfileProvider = ({ children }) => {
	const [profile, setProfile] = useState<Profiles>(null);
	const [branches, setBranches] = useState<GetContactResult>(null);
	const [audiences, setAudiences] = useState<Audiences>(null);

	const { status } = useSession();
	useEffect(() => {
		if (status === 'authenticated') {
			getProfiles(getCMSHostname()).then((profile) => {
				setProfile(profile);
				getAudiences(getCMSHostname(), profile?.membership?.id).then((auds) => {
					setAudiences(auds);
				});
			});
		} else if (status === 'unauthenticated') {
			setProfile(null);
		}
	}, [status]);

	useEffect(() => {
		if (status === 'authenticated') {
		getContact(getCMSHostname())
			.then((response) => {
				setBranches(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
		} else if (status === 'unauthenticated') {
			setBranches(null);
		}
	}, []);

	const value = { profile, branches, audiences };

	return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};
